import React from 'react';
import { Layout, SEO, Container } from 'components/common';
import HomeLink from 'components/theme/Header/Navbar/HomeLink';
import charts from 'assets/illustrations/charts.svg';
import { Header, HeaderContent } from '../templates/data-styles';
import { Wrapper } from '../components/theme/Header/Navbar/styles';

const Success = () => (
  <Layout>
    <SEO />
    <Header>
      <Container>
        <HeaderContent as={Wrapper}>
          <HomeLink />
        </HeaderContent>
      </Container>
    </Header>
    <Container style={{ marginTop: '4rem' }}>
      <Wrapper style={{ alignItems: 'flex-start' }}>
        <div style={{ flex: 1 }}>
          <h1>Success!</h1>
          <p>We appreciate your business.</p>
          <p>You will receive an email with your data shortly.</p>
        </div>
        <img src={charts} alt='' style={{ flex: 1 }} />
      </Wrapper>
    </Container>
  </Layout>
);

export default Success;
